import {
  CLEAR_SELECTED_SEQUENCE_DATA,
  SET_CALENDAR_AUTH,
  SET_CLIENT_DATA,
  SET_COMMON_DROPDOWN,
  SET_EMAIL_MODAL,
  SET_ENTITIES_TAB,
  SET_OPPORTUNITY_DATA,
  SET_OPPORTUNITY_DATA_LIST,
  SET_OPPORTUNITY_DATA_LIST_LOADING,
  SET_OPPORTUNITY_DATA_LOADING,
  SET_SELECTED_OPPORTUNITY_DATA,
  SET_SELECTED_SEQUENCE_DATA,
  SET_SEQUENCE_MODAL_DATA,
  SET_SMS_MODAL,
} from 'actions/types';

const INITIAL_STATE = {
  opportunityDataValue: [],
  opportunityDataValueLoading: false,
  opportunityDataList: [],
  opportunityDataListloading: false,
  entitiesSelectedTab: 1,
  selectedOpportunity: [],
  emailModal: false,
  smsModal: false,
  calendarAuth: {},
  commonDropdown: [],
  selectedSequenceData: [],
  sequenceModalData: {},
  clientData: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_CLIENT_DATA:
      return {
        ...state,
        clientData: action.payload,
      };
    case SET_OPPORTUNITY_DATA:
      return {
        ...state,
        opportunityDataValue: action.payload,
      };

    case SET_OPPORTUNITY_DATA_LOADING:
      return {
        ...state,
        opportunityDataValueLoading: action.payload,
      };

    case SET_OPPORTUNITY_DATA_LIST_LOADING:
      return {
        ...state,
        opportunityDataListloading: action.payload,
      };

    case SET_OPPORTUNITY_DATA_LIST:
      return {
        ...state,
        opportunityDataList: action.payload,
      };

    case SET_SELECTED_OPPORTUNITY_DATA:
      return {
        ...state,
        selectedOpportunity: action.payload,
      };

    case SET_EMAIL_MODAL:
      return {
        ...state,
        emailModal: action.payload,
      };
    case SET_SMS_MODAL:
      return {
        ...state,
        smsModal: action.payload,
      };
    case SET_ENTITIES_TAB:
      return {
        ...state,
        entitiesSelectedTab: action.payload,
      };
    case SET_CALENDAR_AUTH:
      return {
        ...state,
        calendarAuth: action.payload,
      };
    case SET_COMMON_DROPDOWN:
      return {
        ...state,
        commonDropdown: action.payload,
      };
    case SET_SELECTED_SEQUENCE_DATA:
      return {
        ...state,
        selectedSequenceData: action.payload,
      };
    case CLEAR_SELECTED_SEQUENCE_DATA:
      return {
        ...state,
        selectedSequenceData: [],
      };
    case SET_SEQUENCE_MODAL_DATA:
      return {
        ...state,
        sequenceModalData: action.payload,
      };
    default:
      return state;
  }
};
