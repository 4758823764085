import { DropdownType, MultiLineType, SingleChoiceType, SingleLineType } from "./FieldTypes";


export const CommonProperties = {
    property: [
        { label: "Hidden", type: "Checkbox", placeholder: "", name: "hidden", default: false, required: false, size: 12 },
        { label: "Disabled", type: "Checkbox", placeholder: "", name: "disabled", default: false, required: false, size: 12 },
        { label: "Unique", type: "Checkbox", placeholder: "", name: "unique", default: false, required: false, size: 12 },
    ],
    validation: {
        hidden: false,
        disabled: false,
        unique: false,
    }
}


export const SingleLineProperty = {
    property: [
        { label: "required", type: "togglemode", name: "required", required: false, default: false },
        { label: "Validation", type: "select", name: "validation", options: Object.values(SingleLineType).map(item => ({ label: item, value: item })), default: SingleLineType.None, required: true },
        { label: "Field Label", type: "text", placeholder: "Email", name: "label", default: "Email", required: false },
        { label: "Field Placeholder Text", type: "Checkbox", placeholder: "", name: "fieldlabelplaceholderbool", default: true, required: false },
        { label: "", type: "text", placeholder: "Enter xyz", name: "fieldlabelplaceholder", default: "", required: false },
        {
            label: "Character Limit", type: "Checkbox", name: "characterlimit", default: false, required: false, subfields: [
                { label: "Minimum", type: "number", placeholder: "--", name: "minimum", default: null, required: false, size: 6 },
                { label: "Maximum", type: "number", placeholder: "--", name: "maximum", default: null, required: false, size: 6 },

            ]
        },
        ...CommonProperties.property
    ],
    validation: {
        required: false,
        validation: SingleLineType.None,
        label: "Contact Number",
        fieldlabelplaceholderbool: true,
        fieldlabelplaceholder: "Enter Placeholder",
        characterlimit: false,
        submenu: {
            minimum: "",
            maximum: ""
        },
        ...CommonProperties.validation
    }
}


export const MultiLineProperty = {
    property: [
        { label: "required", type: "togglemode", name: "required", required: false, default: false },
        { label: "Field Label", type: "text", placeholder: "Email", name: "label", default: "Email", required: false },
        { label: "Field Placeholder Text", type: "Checkbox", placeholder: "", name: "fieldlabelplaceholderbool", default: true, required: false },
        { label: "", type: "text", placeholder: "Enter xyz", name: "fieldlabelplaceholder", default: "", required: false },
        { label: "Editor Mode", type: "select", name: "editormode", options: Object.values(MultiLineType).map(item => ({ label: item, value: item })), default: MultiLineType.Plain, required: true },
        {
            label: "Character Limit", type: "Checkbox", name: "characterlimit", default: false, required: false, subfields: [
                { label: "Minimum", type: "number", placeholder: "--", name: "minimum", default: null, required: false, size: 6 },
                { label: "Maximum", type: "number", placeholder: "--", name: "maximum", default: null, required: false, size: 6 },

            ]
        },
        ...CommonProperties.property
    ],
    validation: {
        required: false,
        label: "Multi Line",
        fieldlabelplaceholderbool: true,
        fieldlabelplaceholder: "Enter Placeholder",
        characterlimit: false,
        editormode: MultiLineType.Plain,
        submenu: {
            minimum: "",
            maximum: ""
        },
        ...CommonProperties.validation
    }
}



export const DropdownProperty = {
    property: [
        { label: "required", type: "togglemode", name: "required", required: false, default: false },
        { label: "Field Label", type: "text", placeholder: "Email", name: "label", default: "Email", required: false },
        { label: "Field Placeholder Text", type: "Checkbox", placeholder: "", name: "fieldlabelplaceholderbool", default: true, required: false },
        { label: "", type: "text", placeholder: "Enter xyz", name: "fieldlabelplaceholder", default: "", required: false },
        { label: "Predefined Options", type: "select", name: "predefined", discription: "Choose a ready-made list of options (e.g., U.S. states, days, months, etc.)", options: Object.values(DropdownType).map(item => ({ label: item, value: item })), default: DropdownType.None, required: true },
        { label: "Options", type: "TextArea", placeholder: "", name: "options", default: "", required: false, discription: "Give options for users to select from. Enter each option on a new line.", dependent: "predefined", dependentvalue: DropdownType.None },
        { label: "Multiselect Checkbox", type: "Checkbox", name: "multiple", default: false, required: false },
        ...CommonProperties.property
    ],
    validation: {
        required: false,
        label: "Contact Number",
        fieldlabelplaceholderbool: true,
        fieldlabelplaceholder: "Select",
        predefined: DropdownType.None,
        options: "",
        multiple: false,
        ...CommonProperties.validation
    }
}

export const SingleChoiceProperty = {
    property: [
        { label: "required", type: "togglemode", name: "required", required: false, default: false },
        { label: "Field Label", type: "text", placeholder: "Email", name: "label", default: "Email", required: false },
        { label: "Predefined Options", type: "select", name: "predefined", discription: "Choose a ready-made list of options (e.g., U.S. states, days, months, etc.)", options: Object.values(SingleChoiceType).map(item => ({ label: item, value: item })), default: SingleChoiceType.None, required: true },
        { label: "Options", type: "TextArea", placeholder: "", name: "options", default: "", required: false, discription: "Give options for users to select from. Enter each option on a new line.", dependent: "predefined", dependentvalue: SingleChoiceType.None },
        { label: "Display ‘Other’ option", type: "Checkbox", name: "displayoption", default: false, required: false },
        ...CommonProperties.property
    ],
    validation: {
        required: false,
        label: "Label",
        predefined: SingleChoiceType.None,
        options: "",
        displayoption: false,
        ...CommonProperties.validation
    }
}


export const MultipleChoiceProperty = {
    property: [
        { label: "required", type: "togglemode", name: "required", required: false, default: false },
        { label: "Field Label", type: "text", placeholder: "Email", name: "label", default: "Email", required: false },
        { label: "Predefined Options", type: "select", name: "predefined", discription: "Choose a ready-made list of options (e.g., U.S. states, days, months, etc.)", options: Object.values(SingleChoiceType).map(item => ({ label: item, value: item })), default: SingleChoiceType.None, required: true },
        { label: "Options", type: "TextArea", placeholder: "", name: "options", default: "", required: false, discription: "Give options for users to select from. Enter each option on a new line.", dependent: "predefined", dependentvalue: SingleChoiceType.None },
        { label: "Display ‘Other’ option", type: "Checkbox", name: "displayoption", default: false, required: false },
        {
            label: "Selection Limits", type: "Checkbox", name: "selectionlimit", default: false, required: false, subfields: [
                { label: "Minimum", type: "number", placeholder: "--", name: "minimum", default: null, required: false, size: 6 },
                { label: "Maximum", type: "number", placeholder: "--", name: "maximum", default: null, required: false, size: 6 },

            ]
        },
        ...CommonProperties.property
    ],
    validation: {
        required: false,
        label: "Label",
        predefined: SingleChoiceType.None,
        options: "",
        displayoption: false,
        selectionlimit: false,
        submenu: {
            minimum: "",
            maximum: ""
        },
        ...CommonProperties.validation
    }
}




export const NumberProperty = {
    property: [
        { label: "required", type: "togglemode", name: "required", required: false, default: false },
        { label: "Field Label", type: "text", placeholder: "Email", name: "label", default: "Email", required: false },
        { label: "Field Placeholder Text", type: "Checkbox", placeholder: "", name: "fieldlabelplaceholderbool", default: true, required: false },
        { label: "", type: "text", placeholder: "Enter xyz", name: "fieldlabelplaceholder", default: "", required: false },
        {
            label: "Digit Limits", type: "Checkbox", name: "digitlimits", default: false, required: false, subfields: [
                { label: "Minimum", type: "number", placeholder: "--", name: "minimum", default: null, required: false, size: 6 },
                { label: "Maximum", type: "number", placeholder: "--", name: "maximum", default: null, required: false, size: 6 },

            ]
        },
        ...CommonProperties.property
    ],
    validation: {
        required: false,
        label: "Budget",
        fieldlabelplaceholderbool: true,
        fieldlabelplaceholder: "",
        digitlimits: false,
        submenu: {
            minimum: "",
            maximum: ""
        },
        ...CommonProperties.validation
    }
}
