import React from 'react';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import store from './store';
import App from './App';
import './assets/css/ladda.min.css';
import './assets/css/style.css';
import './assets/css/style.scss';
import './assets/css/dpi.css';
import './assets/css/mobileview.scss'
import 'react-phone-input-2/lib/style.css'
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import { createRoot } from 'react-dom/client';

const root = createRoot(document.getElementById('root'));

if (process.env.NODE_ENV !== 'development') {
  console.info = () => {};
  console.log = () => {};
  console.error = () => {};

}
if (false) {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker
      .register(`${process.env.PUBLIC_URL}/service-worker.js`)
      .then((registration) => {
        console.info('Service Worker registered with scope:', registration.scope);
      })
      .catch((error) => {
        console.error('Error registering Service Worker:', error);
      });
  }
}
// const root = createRoot(document.querySelector("#ecollat-entry"));
// root.render(<App />);
root.render(
  <Provider store={store}>
    <ToastContainer
      position="top-center"
      autoClose={2000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
      icon={({ type }) => {
        if (type === 'success')
          return (
            <img src="/manage/images/Toast-svg/Success.svg" className="imageSize" alt="Success" />
          );
        if (type === 'error')
          return <img src="/manage/images/Toast-svg/Error.svg" className="imageSize" alt="Error" />;
        if (type === 'warn')
          return <img src="/manage/images/Toast-svg/Warn.svg" className="imageSize" alt="Warn" />;
      }}
    />
    <App />
  </Provider>,
);
