import {
  ADD_FILE,
  UPDATE_PROGRESS,
  START_UPLOAD,
  UPLOAD_STATUS,
  STOP_UPLOAD,
  REMOVE_FILE,
} from '../actions/types';

const initialState = {
  file: '',
  forReplace: false,
  pdfId: null,
  uploading: false,
  processing: false,
  processingtext:false,
  uploadProgress: {},
  successfullUploaded: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_FILE:
      return {
        ...state,
        file: action.payload,
        forReplace: action.forReplace,
        pdfId: action.pdfId,
        uploadProgress: {
          name: action.payload.name,
          state: 'pending',
          percentage: 0,
        },
      };

    case REMOVE_FILE:
      return {
        ...state,
        file: '',
      };

    case UPDATE_PROGRESS:
      return {
        ...state,
        uploadProgress: action.payload,
      };

    case START_UPLOAD:
      return {
        ...state,
        uploading: true,
      };

    case UPLOAD_STATUS:
      return {
        ...state,
        ...action.payload,
        // successfullUploaded: action.payload.successfullUploaded,
        // uploading: action.payload.uploading,

        // file: '',
        // uploadProgress: {}
      };

    case STOP_UPLOAD:
      return {
        ...state,
        successfullUploaded: false,
        file: '',
      };

    default:
      return state;
  }
};
