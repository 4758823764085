import Axios from 'axios';
import cookie from 'react-cookies';
import { API_URI } from '../Constant';
import { handelError, header } from './Axios';
import { getExpireDate } from '.';

const TOKEN = '_e_token_';
const REFRESH = '_r_token_'

export const requestAuth = async (creds) => {
  return await Axios.post(`${API_URI}/api/auth/signin`, creds, header)
    .then(({ data }) => data)
    .catch(handelError);
};

export const isAuth = () => {
  const token = cookie.load(TOKEN);
  if (!token || token === '') return false;
  else return true;
};

// export const saveToken = (Token, expires) => {
//   const tokenexpiry = getExpireDate(expires/( 24 * 60 * 60 * 1000))
//   localStorage.setItem(TOKEN, tokenexpiry)

//   if (Token) cookie.save(TOKEN, Token, { expires: tokenexpiry });
// };
export const saveRefreshToken = (Token, expires) => {
  const refreshexpiry = getExpireDate(expires/( 24 * 60 * 60 * 1000))
  localStorage.setItem(REFRESH, refreshexpiry)
  if (Token) cookie.save(REFRESH, Token, { expires: refreshexpiry });
};
export const getToken = () => {
  return cookie.load(TOKEN);
};
export const getRefreshToken = () => {
  return cookie.load(REFRESH);
};
export const removeToken = () => {
  cookie.remove(TOKEN);
  cookie.remove(REFRESH);

};

export const saveToken = (Token) => {
  if (Token) cookie.save(TOKEN, Token, { expires: getExpireDate() });
};