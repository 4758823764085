import Axios from '../functions/Axios';
import { API_URI, ANALYTICS_VISITORS, ANALYTICS_VIEWS, ANALYTICS_WORDS } from '../Constant';

const VIEWS = 'PDF_VIEWS';
const VISITORS = 'SITE_VISITORS';
const WORDS = 'SEARCHED_WORDS';

const initialState = {
  views: [],
  visitors: [],
  words: [],
};

const addSearchData = (searchTerms) => {
  return {
    type: WORDS,
    payload: searchTerms,
  };
};

const addViewsData = (pdfViews) => {
  return {
    type: VIEWS,
    payload: pdfViews,
  };
};

const addVisitorsData = (siteVisitors) => {
  return {
    type: VISITORS,
    payload: siteVisitors,
  };
};

export const getPdfViews = () => (dispatch) => {
  return Axios.post(`${API_URI}/${ANALYTICS_VIEWS}`)
    .then((res) => dispatch(addViewsData(res.data)))
    .catch((err) => {
    });
};

export const getSiteVisitors = () => (dispatch) => {
  return Axios.post(`${API_URI}/${ANALYTICS_VISITORS}`)
    .then((res) => dispatch(addVisitorsData(res.data)))
    .catch((err) => {});
};

export const getSearchedWords = () => (dispatch) => {
  return Axios.post(`${API_URI}/${ANALYTICS_WORDS}`)
    .then((res) => dispatch(addSearchData(res.data)))
    .catch((err) => {});
};

export default (state = initialState, action) => {
  switch (action.type) {
    case VIEWS:
      return {
        ...state,
        views: [...action.payload],
      };
    case VISITORS:
      return {
        ...state,
        visitors: [...action.payload],
      };
    case WORDS:
      return {
        ...state,
        words: [...action.payload],
      };
    default:
      return state;
  }
};
