import { combineReducers } from 'redux';
import upload from './uploadReducer';
import ui from './ui';
import pdf from './pdfReducer';
import products from './productMasterReducer';
import user from './userReducer';
import analytics from './analytics';
import modules from './modules';
import bigcomproposal from './bigcomproposal';
// import cartReducer from './cartReducer';
import cms from './headlesscsmReducer'
import crm from './CrmReducer';
import form from "./FormBuilderReducer"

import { USER_LOGOUT } from '../actions/types';

const appReducer = combineReducers({
  upload,
  pdf,
  ui,
  products,
  user,
  analytics,
  modules,
  bigcomproposal,
  cms,
  crm,
  form
});

const rootReducer = (state, action) => {
  if (action.type === USER_LOGOUT) {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
