import { SET_USER, USER_LOGOUT } from '../actions/types';

const initialState = {
  isLoading: true,
  authorized: false,
  modules: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      const { first_name, last_name, plan_type, role_names, userID, type_user } = action.payload;
      return {
        ...state,
        isLoading: false,
        authorized: true,
        first_name,
        last_name,
        plan_type,
        modules: role_names,
        userID,
        isadmin: type_user === "ROLE_ADMIN" ? true : false
      };

    case USER_LOGOUT:
      return {
        ...state,
        isLoading: false,
        authorized: false,
      };
    default:
      return state;
  }
};
