import { SET_LOADING, ADD_TOAST, MENU_TOGGLER } from 'actions/types';

const INITIAL_STATE = {
  loading: true,
  toasts: [],
  menuClose: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    case ADD_TOAST:
      return {
        ...state,
        toasts: [...state.toasts, action.payload],
      };

    case MENU_TOGGLER:
      return {
        ...state,
        menuClose: !state.menuClose,
      };

    default:
      return state;
  }
};
