// upload action types
export const ADD_FILE = 'ADD_FILE';
export const REMOVE_FILE = 'REMOVE_FILE';
export const UPDATE_PROGRESS = 'UPDATE_PROGRESS';
export const START_UPLOAD = 'START_UPLOAD';
export const UPLOAD_STATUS = 'UPLOAD_STATUS';
export const STOP_UPLOAD = 'STOP_UPLOAD';

// Pdf types
export const GET_PDF_LIST = 'GET_PDF_LIST';
export const GET_PDF_LIST_SUCCESS = 'GET_PDF_LIST_SUCCESS';
export const GET_PDF_LIST_ERROR = 'GET_PDF_LIST_ERROR';
export const UPDATE_PDF = 'UPDATE_PDF';
export const UPDATE_PDF_SUCCESS = 'UPDATE_PDF_SUCCESS';
export const UPDATE_PDF_ERROR = 'UPDATE_PDF_ERROR';
export const DELETE_PDF = 'DELETE_PDF';
export const DELETE_PDF_SUCCESS = 'DELETE_PDF_SUCCESS';
export const DELETE_PDF_ERROR = 'DELETE_PDF_ERROR';

// Product Master types
// GET LIST
export const GET_PRODUCT_LIST = 'GET_PRODUCT_LIST';
export const GET_ALL_PRODUCT_LIST_SUCCESS = 'GET_ALL_PRODUCT_LIST_SUCCESS';
export const GET_PRODUCT_LIST_SUCCESS = 'GET_PRODUCT_LIST_SUCCESS';
export const GET_PRODUCT_LIST_ERROR = 'GET_PRODUCT_LIST_ERROR';
// GET PRODUCT
export const PRODUCT_GET = 'PRODUCT_GET';
export const PRODUCT_GET_SUCCESS = 'PRODUCT_GET_SUCCESS';
export const PRODUCT_GET_ERROR = 'PRODUCT_GET_ERROR';
// ADD
export const PRODUCT_ADD = 'PRODUCT_ADD';
export const PRODUCT_ADD_SUCCESS = 'PRODUCT_ADD_SUCCESS';
export const PRODUCT_ADD_ERROR = 'PRODUCT_ADD_ERROR';
// EDIT
export const PRODUCT_EDIT = 'PRODUCT_EDIT';
export const PRODUCT_EDIT_SUCCESS = 'PRODUCT_EDIT_SUCCESS';
export const PRODUCT_EDIT_ERROR = 'PRODUCT_EDIT_ERROR';
// DELETE
export const PRODUCT_DELETE = 'PRODUCT_DELETE';
export const PRODUCT_DELETE_SUCCESS = 'PRODUCT_DELETE_SUCCESS';
export const PRODUCT_DELETE_ERROR = 'PRODUCT_DELETE_ERROR';
// PAGE NO
export const PRODUCT_CHANGE_PAGE_NO = 'PRODUCT_CHANGE_PAGE_NO';

// PAGE SIZE
export const PRODUCT_CHANGE_PAGE_SIZE = 'PRODUCT_CHANGE_PAGE_SIZE';

// PAGE SEARCH
export const PRODUCT_CHANGE_PAGE_SEARCH = 'PRODUCT_CHANGE_PAGE_SEARCH';

// PDF STATUS
export const PDF_STATUS_UPDATE = 'PDF_STATUS_UPDATE';
export const PDF_LISTSTATUS_UPDATE = 'PDF_LISTSTATUS_UPDATE';

// USER
export const SET_USER = 'SET_USER';
export const USER_LOGOUT = 'USER_LOGOUT';

//CART PRODUCTS
export const GET_PRODUCT_CART_LIST = 'GET_PRODUCT_CART_LIST';
export const GET_PRODUCT_CART_LIST_SUCCESS = 'GET_PRODUCT_CART_LIST_SUCCESS';
export const GET_PRODUCT_CART_LIST_ERROR = 'GET_PRODUCT_CART_LIST_ERROR';

//CATEGORIES
export const GET_CATEGORIES_LIST = 'GET_CATEGORIES_LIST';
export const GET_CATEGORIES_LIST_SUCCESS = 'GET_CATEGORIES_LIST_SUCCESS';
export const GET_CATEGORIES_LIST_ERROR = 'GET_CATEGORIES_LIST_ERROR';
export const ADD_CATEGORY = 'ADD_CATEGORY';
export const ADD_CATEGORY_SUCCESS = 'ADD_CATEGORY_SUCCESS';
export const ADD_CATEGORY_ERROR = 'ADD_CATEGORY_ERROR';
export const DELETE_CATEGORY = 'DELETE_CATEGORY';
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS';
export const DELETE_CATEGORY_ERROR = 'DELETE_CATEGORY_ERROR';
export const EDIT_CATEGORY = 'EDIT_CATEGORY';
export const EDIT_CATEGORY_SUCCESS = 'EDIT_CATEGORY_SUCCESS';
export const EDIT_CATEGORY_ERROR = 'EDIT_CATEGORY_ERROR';

// Sub Categories
export const ADD_SUB_CATEGORY = 'ADD_SUB_CATEGORY';
export const ADD_SUB_CATEGORY_SUCCESS = 'ADD_SUB_CATEGORY_SUCCESS';
export const ADD_SUB_CATEGORY_ERROR = 'ADD_SUB_CATEGORY_ERROR';

// MODULES
export const SET_MODULES = 'SET_MODULES';

//UI
export const SET_LOADING = 'SET_LOADING';
export const ADD_TOAST = 'ADD_TOAST';
export const MENU_TOGGLER = 'MENU_TOGGLER';


// BIGCOMMERCE
export const GET_BIGCOMMERCE_PRODUCT_CART_LIST = 'GET_BIGCOMMERCE_PRODUCT_CART_LIST';
export const GET_BIGCOMMERCE_PRODUCT_CART_LIST_SUCCESS = 'GET_BIGCOMMERCE_PRODUCT_CART_LIST_SUCCESS';
export const GET_BIGCOMMERCE_PRODUCT_CART_LIST_ERROR = 'GET_BIGCOMMERCE_PRODUCT_CART_LIST_ERROR';



//HeadLess CMS
// Collection
export const SET_CONTENT_TYPE_COLLECTION = 'SET_CONTENT_TYPE_COLLECTION'
export const SET_CONTENT_TYPE_SINGLE = 'SET_CONTENT_TYPE_SINGLE'
export const SET_CONTENT_TYPE_COMPONENTS = 'SET_CONTENT_TYPE_COMPONENTS'
export const SET_CONTENT_TYPE_SUCCESS = 'SET_CONTENT_TYPE_SUCCESS'
export const SET_CONTENT_TYPE_LOADING = 'SET_CONTENT_TYPE_LOADING'
export const SET_CONTENT_TYPE_ERROR = 'SET_CONTENT_TYPE_ERROR'


// Media FIles 
export const SET_MEDIA_LIBRARY_CONTENT = 'SET_MEDIA_LIBRARY_CONTENT'
export const LOADER_MEDIA_LIBRARY_CONTENT = 'LOADER_MEDIA_LIBRARY_CONTENT'
export const ERROR_MEDIA_LIBRARY_CONTENT = 'ERROR_MEDIA_LIBRARY_CONTENT'

// Domians
export const CMS_DOMAINS="CMS_DOMAINS"

// Navigation 

export const CMS_GET_NAVIGATIONS ="CMS_GET_NAVIGATIONS"
export const CMS_DELETE_NAVIGATIONS ="CMS_DELETE_NAVIGATIONS"




//CRM
export const SET_CLIENT_DATA = 'SET_CLIENT_DATA';
export const SET_OPPORTUNITY_DATA = 'SET_OPPORTUNITY_DATA';
export const SET_OPPORTUNITY_DATA_LOADING = 'SET_OPPORTUNITY_DATA_LOADING';
export const SET_OPPORTUNITY_DATA_LIST = 'SET_OPPORTUNITY_DATA_LIST';
export const SET_OPPORTUNITY_DATA_LIST_LOADING = 'SET_OPPORTUNITY_DATA_LIST_LOADING';
export const SET_SELECTED_OPPORTUNITY_DATA = 'SET_SELECTED_OPPORTUNITY_DATA';
export const SET_EMAIL_MODAL = 'SET_EMAIL_MODAL';
export const SET_ENTITIES_TAB = 'SET_ENTITIES_TAB';
export const SET_SMS_MODAL = 'SET_SMS_MODAL';
export const SET_CALENDAR_AUTH = 'SET_CALENDAR_AUTH';
export const SET_COMMON_DROPDOWN = 'SET_COMMON_DROPDOWN';
export const SET_SELECTED_SEQUENCE_DATA = 'SET_SELECTED_SEQUENCE_DATA';
export const CLEAR_SELECTED_SEQUENCE_DATA = 'CLEAR_SELECTED_SEQUENCE_DATA';
export const SET_SEQUENCE_MODAL_DATA = 'SET_SEQUENCE_MODAL_DATA';


// Form Builder Types 
export const FORM_SET_LOADING = "FORM_SET_LOADING"
export const FORM_SET_ERROR = "FORM_SET_ERROR"
export const ADD_Item = 'ADD_Item'
export const CHANGE_TYPE = 'CHANGE_TYPE'
export const UPDATE_PROPERTY = 'UPDATE_PROPERTY'
export const CHANGE_ORGER = 'CHANGE_ORGER'
export const SELECT_ITEM = 'SELECT_ITEM'
export const GET_FORM_DATA = 'GET_FORM_DATA'
export const GET_FORM_SETTING = 'GET_FORM_SETTING'
export const DELETE_SELECT_ITEM = 'DELETE_SELECT_ITEM'
export const UPDATE_MAIL_CONFIGRATIONS = 'UPDATE_MAIL_CONFIGRATIONS'
