import { CommonProperties } from "./BasicFielsProperties";
import { CityDropdownType, DateFormet, NameTypes, ReCaptchaType, TermsType, TimeFormets } from "./FieldTypes";

export const NameProperty = {
    [NameTypes.Segregated]: {
        property: [
            { label: "required", type: "togglemode", name: "required", required: false, default: false },
            { label: "Type", type: "select", name: "type", options: Object.values(NameTypes).map(item => ({ label: item, value: item })), default: NameTypes.Segregated, required: true },
            { label: "First Name Label", type: "text", placeholder: "First Name", name: "firstname", default: "Form", required: false },
            { label: "First Name Placeholder Text", type: "Checkbox", placeholder: "First Name", name: "firstplaceholderbool", default: "Form", required: false },
            { label: "", type: "text", placeholder: "Enter your First name", name: "firstplaceholder", default: "Form", required: false },

            { label: "Last Name Label", type: "text", placeholder: "Last Name", name: "lastname", default: "Form", required: false },
            { label: "Last Name Placeholder Text", type: "Checkbox", placeholder: "Last Name", name: "lastplaceholderbool", default: "Form", required: false },
            { label: "", type: "text", placeholder: "Enter your Last name", name: "lastplaceholder", default: "Form", required: false },
            { label: "Salutation", type: "Checkbox", name: "salutation", default: false, required: false },
            ...CommonProperties.property
        ],
        validation: {
            firstname: "First Name",
            firstplaceholderbool: true,
            firstplaceholder: "Enter your First name",
            lastname: "Last Name",
            lastplaceholderbool: true,
            lastplaceholder: "Enter your Last name",
            salutation: false,
            type: NameTypes.Segregated,
            required: false,
            ...CommonProperties.validation

        }
    },
    [NameTypes.Full]: {
        property: [
            { label: "required", type: "togglemode", name: "required", required: false, default: false },
            { label: "Type", type: "select", name: "type", options: Object.values(NameTypes).map(item => ({ label: item, value: item })), default: NameTypes.Segregated, required: true },
            { label: "Full Name Label", type: "text", placeholder: "Full Name", name: "fullname", default: "Full Name", required: false },
            { label: "Full Name Placeholder Text", type: "Checkbox", placeholder: "Enter Your Full Name", name: "fullnameplaceholderbool", default: "Form", required: false },
            { label: "", type: "text", placeholder: "Enter your Full Name", name: "fullnameplaceholder", default: "Enter your Full name", required: false },
            { label: "Salutation", type: "Checkbox", name: "salutation", default: false, required: false },
            ...CommonProperties.property
        ],
        validation: {
            fullname: "Full Name",
            fullnameplaceholderbool: true,
            fullnameplaceholder: "Enter your Full name",
            salutation: false,
            type: NameTypes.Full,
            required: false,
            ...CommonProperties.validation

        }
    }
}

export const EmailProperty = {
    property: [
        { label: "required", type: "togglemode", name: "required", required: false, default: false },
        { label: "Field Label", type: "text", placeholder: "Email", name: "label", default: "Email", required: false },
        { label: "Field Label Placeholder Text", type: "Checkbox", placeholder: "", name: "fieldlabelplaceholderbool", default: true, required: false },
        { label: "", type: "text", placeholder: "Enter your email", name: "fieldlabelplaceholder", default: "Enter your email", required: false },
        { label: "Confirmation Field", type: "Checkbox", name: "confirmationfield", default: false, required: false },
        ...CommonProperties.property
    ],
    validation: {
        required: false,
        label: "Email",
        fieldlabelplaceholderbool: true,
        fieldlabelplaceholder: "Enter your email",
        confirmationfield: false,
        ...CommonProperties.validation
    }
}

export const AddressProperty = {
    property: [
        // Address Line One 
        { label: "Address Line 1", type: "Checkbox", name: "addresslineonebool", default: true, required: false, size: 6 },
        { label: "required", type: "togglemode", name: "requiredAddressone", required: false, default: false, size: 6 },
        { label: "Address Line 1 Placeholder Text", type: "Checkbox", placeholder: "", name: "Addressoneplaceholderbool", default: true, required: false },
        { label: "", type: "text", placeholder: "Enter  address line 1", name: "addressoneplaceholder", default: "Enter  address line 1", required: false },

        // Address Line Two

        { label: "Address Line 2", type: "Checkbox", name: "addresslinetwobool", default: true, required: false, size: 6 },
        { label: "required", type: "togglemode", name: "requiredaddresstwo", required: false, default: false, size: 6 },
        { label: "Address Line 1 Placeholder Text", type: "Checkbox", placeholder: "", name: "Addresstwoplaceholderbool", default: true, required: false },
        { label: "", type: "text", placeholder: "Enter  address line 2", name: "Addresstwoplaceholder", default: "Enter  address line 2", required: false },

        // City

        { label: "City", type: "Checkbox", name: "citycheckbox", default: true, required: false, size: 6 },
        { label: "required", type: "togglemode", name: "requiredCity", required: false, default: false, size: 6 },
        { label: "", type: "select", name: "citytype", multi: true, options: Object.values(CityDropdownType).map(item => ({ label: item, value: item })), default: "", required: false },
        { label: "Options", type: "TextArea", placeholder: "", name: "options", default: "", required: false, discription: "Give options for users to select from. Enter each option on a new line.", dependent: "citytype", dependentvalue: CityDropdownType.Dropdown },
        { label: "City Placeholder Text", type: "Checkbox", placeholder: "", name: "cityeplaceholderbool", default: true, required: false },
        { label: "", type: "text", placeholder: "Enter  City Placeholder", name: "cityplaceholder", default: "City", required: false },


        // State 
        { label: "State/Province", type: "Checkbox", name: "Statecheckbox", default: true, required: false, size: 6 },
        { label: "required", type: "togglemode", name: "requiredstate", required: false, default: false, size: 6 },
        { label: "", type: "select", name: "statetype", multi: true, options: Object.values(CityDropdownType).map(item => ({ label: item, value: item })), default: "", required: false },

        { label: "State/Province Placeholder Text", type: "Checkbox", placeholder: "", name: "stateplaceholderbool", default: true, required: false },
        { label: "", type: "text", placeholder: "Enter  State Placeholder", name: "stateplaceholder", default: "City", required: false },





        // Country 


        { label: "Country", type: "Checkbox", name: "countrycheckbox", default: true, required: false, size: 6 },
        { label: "required", type: "togglemode", name: "requiredcountry", required: false, default: false, size: 6 },
        { label: "", type: "select", name: "countrytype", multi: true, options: Object.values(CityDropdownType).map(item => ({ label: item, value: item })), default: "", required: false },
        // { label: "Options", type: "TextArea", placeholder: "", name: "options", default: "", required: false, discription: "Give options for users to select from. Enter each option on a new line.", dependent: "citytype", dependentvalue: CityDropdownType.Dropdown },
        { label: "City Placeholder Text", type: "Checkbox", placeholder: "", name: "countryeplaceholderbool", default: true, required: false },
        { label: "", type: "text", placeholder: "Enter  City Placeholder", name: "countryplaceholder", default: "City", required: false },




        // zipcode
        { label: "Zip/Postal Code", type: "Checkbox", name: "zipcheckbox", default: true, required: false, size: 6 },
        { label: "required", type: "togglemode", name: "requiredzip", required: false, default: false, size: 6 },
        { label: "Zip/Postal Code Placeholder Text", type: "Checkbox", placeholder: "", name: "zipeplaceholderbool", default: true, required: false },
        { label: "", type: "text", placeholder: "Enter  Zip/Postal Code Placeholder", name: "zipplaceholder", default: "City", required: false },

        ...CommonProperties.property

    ],
    validation: {
        required: false,

        addresslineonebool: true,
        requiredAddressone: false,
        Addressoneplaceholderbool: true,
        addressoneplaceholder: "Enter  address line 1",


        addresslinetwobool: true,
        requiredaddresstwo: false,
        Addresstwoplaceholderbool: true,
        Addresstwoplaceholder: "Enter  address line 1",


        citycheckbox: true,
        requiredCity: true,
        citytype: CityDropdownType.Dropdown,
        options: "",
        cityeplaceholderbool: true,
        cityplaceholder: "Enter / Select City",



        Statecheckbox: true,
        requiredstate: false,
        statetype: CityDropdownType.Dropdown,
        stateplaceholderbool: true,
        stateplaceholder: "Enter / Select State",



        countrycheckbox: true,
        requiredcountry: false,
        countrytype: CityDropdownType.Dropdown,
        countryeplaceholderbool: true,
        countryplaceholder: "Enter Country",



        zipcheckbox: true,
        requiredzip: false,
        zipeplaceholderbool: true,
        zipplaceholder: "Enter Zip / Postal Code",

        ...CommonProperties.validation

    }
}


export const ContactProperty = {
    property: [
        { label: "required", type: "togglemode", name: "required", required: false, default: false },
        { label: "Field Label", type: "text", placeholder: "Email", name: "label", default: "Email", required: false },
        { label: "Field Placeholder Text", type: "Checkbox", placeholder: "", name: "fieldlabelplaceholderbool", default: true, required: false },
        { label: "", type: "text", placeholder: "Enter xyz", name: "fieldlabelplaceholder", default: "", required: false },
        {
            label: "Country Code", type: "Checkbox", name: "countrycode", default: false, required: false, subfields: [
                {
                    label: "", type: "radio", name: "countytype", options: [
                        { label: "All countries", value: "All countries", countries: [] },
                        { label: "Show selected countries", value: "Show selected countries", countries: [] }
                    ], required: false, default: false
                },
                { label: "Number Limit", type: "Checkbox", name: "numberlimit", default: false, required: false },
                { label: "Minimum", type: "number", placeholder: "--", name: "minimum", default: null, required: false, size: 6 },
                { label: "Maximum", type: "number", placeholder: "--", name: "maximum", default: null, required: false, size: 6 },

            ]
        },
        ...CommonProperties.property
    ],
    validation: {
        required: false,
        label: "Contact Number",
        fieldlabelplaceholderbool: true,
        fieldlabelplaceholder: "",
        countrycode: false,
        submenu: {
            countytype: "All countries",
            numberlimit: false,
            minimum: "",
            maximum: ""
        },
        ...CommonProperties.validation

    }
}
export const DateTimeProperty = {
    property: [
        { label: "required", type: "togglemode", name: "required", required: false, default: false },
        { label: "Validation" },
        { label: "Past Date", type: "Checkbox", placeholder: "", name: "pastdate", default: true, required: false, size: 6 },
        { label: "Present Date", type: "Checkbox", placeholder: "", name: "presentdate", default: true, required: false, size: 6 },
        { label: "Future Date", type: "Checkbox", placeholder: "", name: "futuredate", default: true, required: false, size: 6 },
        { label: "Field Label", type: "text", placeholder: "Date", name: "label", default: "Date", required: false },
        { label: "Field Placeholder Text", type: "Checkbox", placeholder: "", name: "fieldlabelplaceholderbool", default: true, required: false },
        { label: "", type: "text", placeholder: "Enter xyz", name: "fieldlabelplaceholder", default: "", required: false },
        { label: "Date Format", type: "select", name: "dateformat", options: Object.values(DateFormet).map(item => ({ label: item, value: item })), default: Object.values(DateFormet)[0], required: false },
        {
            label: "Time Field", type: "Checkbox", name: "timefield", default: false, required: false, subfields: [
                { label: "Time Format", type: "select", name: "timeformat", options: Object.values(TimeFormets).map(item => ({ label: item, value: item })), default: Object.values(TimeFormets)[0], required: false },
            ]
        },
        ...CommonProperties.property
    ],
    validation: {
        required: false,
        pastdate: true,
        presentdate: true,
        futuredate: true,
        label: "Date",
        fieldlabelplaceholderbool: true,
        fieldlabelplaceholder: "",
        dateformat: Object.values(DateFormet)[0],
        timefield: false,
        submenu: {
            timeformat: Object.values(TimeFormets)[0]
        },
        ...CommonProperties.validation

    }
}


export const SliderProperty = {
    property: [
        { label: "required", type: "togglemode", name: "required", required: false, default: false },
        { label: "Field Label", type: "text", placeholder: "Date", name: "label", default: "label", required: false },
        { label: "Minimum Value", type: "number", placeholder: "--", name: "minimum", default: null, required: false, size: 6 },
        { label: "Maximum Value", type: "number", placeholder: "--", name: "maximum", default: null, required: false, size: 6 },
        ...CommonProperties.property
    ],
    validation: {
        required: false,
        label: "label",
        minimum: 0,
        maximum: 100,
        ...CommonProperties.validation
    }
}




export const UrlProperty = {
    property: [
        { label: "required", type: "togglemode", name: "required", required: false, default: false },
        { label: "Field Label", type: "text", placeholder: "Portfolio Link", name: "label", default: "Portfolio Link", required: false },
        { label: "Field Placeholder Text", type: "Checkbox", placeholder: "", name: "fieldlabelplaceholderbool", default: true, required: false },
        { label: "", type: "text", placeholder: "Enter xyz", name: "fieldlabelplaceholder", default: "Paste your portfolio URL here", required: false },
        ...CommonProperties.property
    ],
    validation: {
        required: false,
        label: "Portfolio Link",
        fieldlabelplaceholderbool: true,
        fieldlabelplaceholder: "Paste your portfolio URL here",
        ...CommonProperties.validation
    }
}

export const TermConditionProperty = {
    [TermsType["With Terms & Conditions text field"]]: {
        property: [
            { label: "required", type: "togglemode", name: "required", required: false, default: false },
            { label: "Type", type: "select", name: "type", options: Object.values(TermsType).map(item => ({ label: item, value: item })), default: TermsType["With Terms & Conditions text field"], required: false },
            { label: "Field Text", type: "text", placeholder: "Portfolio Link", name: "label", default: "Terms & Conditions", required: false },
            { label: "Field Placeholder Text", type: "Checkbox", placeholder: "", name: "fieldlabelplaceholderbool", default: true, required: false },
            { label: "", type: "text", placeholder: "Enter xyz", name: "fieldlabelplaceholder", default: "Your terms & conditions go here...", required: false },
            ...CommonProperties.property
        ],
        validation: {
            required: false,
            type: TermsType["With Terms & Conditions text field"],
            label: "Terms & Conditions",
            fieldlabelplaceholderbool: true,
            fieldlabelplaceholder: "Your terms & conditions go here...",
            ...CommonProperties.validation
        }
    },
    [TermsType["Without Terms & Conditions text field"]]: {
        property: [
            { label: "required", type: "togglemode", name: "required", required: false, default: false },
            { label: "Type", type: "select", name: "type", options: Object.values(TermsType).map(item => ({ label: item, value: item })), default: TermsType["Without Terms & Conditions text field"], required: false },
            { label: "Terms & Conditions URL", type: "text", placeholder: "https//www.abc.com", name: "termsurl", default: "", required: false },
            ...CommonProperties.property
        ],
        validation: {
            required: false,
            type: TermsType["Without Terms & Conditions text field"],
            label: "I accept the Terms & Conditions",
            termsurl: "",
            ...CommonProperties.validation
        }
    }
}


export const ReCaptchaProperty = {
    [ReCaptchaType["Google Captcha"]]: {
        property: [
            // { label: "required", type: "togglemode", name: "required", required: false, default: false },
            { label: "Type", type: "select", name: "type", options: Object.values(ReCaptchaType).map(item => ({ label: item, value: item })), default: ReCaptchaType["Google Captcha"], required: false },
            { label: "Enter Site Key", type: "text", placeholder: "Enter Site Key", name: "sitekey", default: "", required: true },
            { label: "Enter Secret Key", type: "text", placeholder: "Enter Secret Key", name: "secretkey", default: "", required: true },

            // ...CommonProperties.property
        ],
        validation: {
            // required: false,
            type: ReCaptchaType["Google Captcha"],
            label: "Portfolio Link",
            sitekey: "",
            secretkey: ""
            // ...CommonProperties.validation
        }
    },
    [ReCaptchaType.hCaptcha]: {
        property: [
            // { label: "required", type: "togglemode", name: "required", required: false, default: false },
            { label: "Type", type: "select", name: "type", options: Object.values(ReCaptchaType).map(item => ({ label: item, value: item })), default: ReCaptchaType.hCaptcha, required: false },
            { label: "Enter Site Key", type: "text", placeholder: "Enter Site Key", name: "sitekey", default: "", required: true },
            // { label: "Enter Secret Key", type: "text", placeholder: "Enter Secret Key", name: "secretkey", default: "", required: true },

            // ...CommonProperties.property
        ],
        validation: {
            // required: false,
            type: ReCaptchaType.hCaptcha,
            label: "Portfolio Link",
            sitekey: "",
            secretkey: ""
            // ...CommonProperties.validation
        }
    },

}