import {
  GET_PRODUCT_CART_LIST,
  GET_PRODUCT_CART_LIST_SUCCESS,
  GET_PRODUCT_CART_LIST_ERROR,
  GET_PRODUCT_LIST,
  GET_PRODUCT_LIST_SUCCESS,
  GET_PRODUCT_LIST_ERROR,
  PRODUCT_DELETE,
  PRODUCT_DELETE_SUCCESS,
  PRODUCT_DELETE_ERROR,
  PRODUCT_ADD,
  PRODUCT_ADD_SUCCESS,
  PRODUCT_ADD_ERROR,
  PRODUCT_GET,
  PRODUCT_GET_SUCCESS,
  PRODUCT_GET_ERROR,
  PRODUCT_EDIT,
  PRODUCT_EDIT_SUCCESS,
  PRODUCT_EDIT_ERROR,
  PRODUCT_CHANGE_PAGE_NO,
  GET_CATEGORIES_LIST,
  GET_CATEGORIES_LIST_SUCCESS,
  GET_CATEGORIES_LIST_ERROR,
  ADD_CATEGORY,
  ADD_CATEGORY_SUCCESS,
  ADD_CATEGORY_ERROR,
  DELETE_CATEGORY,
  DELETE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_ERROR,
  EDIT_CATEGORY,
  EDIT_CATEGORY_SUCCESS,
  EDIT_CATEGORY_ERROR,
  PRODUCT_CHANGE_PAGE_SIZE,
  GET_ALL_PRODUCT_LIST_SUCCESS,
  PRODUCT_CHANGE_PAGE_SEARCH,
  ADD_SUB_CATEGORY,
  ADD_SUB_CATEGORY_SUCCESS,
  ADD_SUB_CATEGORY_ERROR,
} from '../actions/types';

const initialState = {
  loading: true,
  categoryLoading: true,
  product: {},
  productList: [],
  productmasterList: [],
  Allproductlist: [],
  error: false,
  errorMessage: '',
  currentPage: 1,
  pageLimit: 10,
  pagesearch: '',
  categories: [],
  subcategories: []
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CATEGORIES_LIST:
      return {
        ...state,
        categoryLoading: true,
      };
    case GET_CATEGORIES_LIST_SUCCESS:
      return {
        ...state,
        categories: action.payload,
        categoryLoading: false,
        error: false,
        errorMessage: '',
      };
    case GET_CATEGORIES_LIST_ERROR:
      return {
        ...state,
        categoryLoading: false,
        error: true,
        errorMessage: action.payload,
      };
    case GET_PRODUCT_CART_LIST:
      return {
        ...state,
        loading: true,
      };
    case GET_PRODUCT_CART_LIST_SUCCESS:
      return {
        ...state,
        productList: action.payload,
        loading: false,
        error: false,
        errorMessage: '',
      };
    case GET_PRODUCT_CART_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
      };
    case GET_PRODUCT_LIST:
      return {
        ...state,
        loading: true,
      };

    case GET_PRODUCT_LIST_SUCCESS:
      return {
        ...state,
        productmasterList: action.payload,
        loading: false,
        error: false,
        errorMessage: '',
      };
    case GET_ALL_PRODUCT_LIST_SUCCESS:
      return {
        ...state,
        Allproductlist: action.payload,
        loading: false,
        error: false,
        errorMessage: '',
      };

    case GET_PRODUCT_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
      };

    case PRODUCT_GET:
      return {
        ...state,
        loading: true,
      };

    case PRODUCT_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        product: action.payload,
      };

    case PRODUCT_GET_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case PRODUCT_DELETE:
      return {
        ...state,
        loading: true,
      };

    case PRODUCT_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        productList: state.productList.filter((product) => product.sku_code !== action.payload),
        error: false,
      };

    case PRODUCT_DELETE_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case PRODUCT_ADD:
      return {
        ...state,
        loading: true,
      };

    case PRODUCT_ADD_SUCCESS:
      let list = state.productList;
      list.unshift(action.payload);
      return {
        ...state,
        loading: false,
        productList: list,
      };

    case PRODUCT_ADD_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case PRODUCT_EDIT:
      return {
        ...state,
        loading: true,
      };

    case PRODUCT_EDIT_SUCCESS:
      return {
        ...state,
        loading: false,
        productList: state.productList.map((prod) => {
          if (prod.sku_code === action.payload.sku_code) {
            return action.payload;
          } else {
            return prod;
          }
        }),
        error: false,
      };

    case PRODUCT_EDIT_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case ADD_CATEGORY:
      return {
        ...state,
        loading: true,
      };
    case ADD_CATEGORY_SUCCESS:
      let categories = state.categories;
      categories.push(action.payload);
      return {
        ...state,
        loading: false,
        categories: categories,
      };
    case ADD_CATEGORY_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case ADD_SUB_CATEGORY:
      return {
        ...state,
        loading: true,
      };
    case ADD_SUB_CATEGORY_SUCCESS:
      let subcatagories = state.categories;
      subcatagories.push(action.payload);
      return {
        ...state,
        loading: false,
        subcatagories: subcatagories,
      };
    case ADD_SUB_CATEGORY_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case EDIT_CATEGORY:
      return {
        ...state,
        loading: false,
      };
    case EDIT_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        categories: state.categories.map((prod) => {
          if (prod.products_cat_id === action.payload.products_cat_id) {
            return action.payload;
          } else {
            return prod;
          }
        }),
        error: false,
      };
    case EDIT_CATEGORY_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case DELETE_CATEGORY:
      return {
        ...state,
        loading: true,
      };

    case DELETE_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        categories: state.categories.filter(
          (product) => product.products_cat_id !== action.payload,
        ),
        error: false,
      };

    case DELETE_CATEGORY_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case PRODUCT_CHANGE_PAGE_NO:
      return {
        ...state,
        currentPage: action.payload,
      };
    case PRODUCT_CHANGE_PAGE_SIZE:
      return {
        ...state,
        pageLimit: action.payload,
      };
    case PRODUCT_CHANGE_PAGE_SEARCH:
      return {
        ...state,
        pagesearch: action.payload,
      };
    default:
      return state;
  }
};
