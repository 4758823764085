import { getRandomID } from 'functions';

export const ButtonC = (props) => {
  // Define CSS classes for different button sizes
  const buttonSizes = {
    sm: 'small-button',
    md: 'medium-button',
    lg: 'large-button',
  };
  const buttonColors = {
    Primary: 'Primary-Button',
    Secondary: 'Secondary-Button',
    Tertiary: 'Tertiary-Button',
    Forth: 'Forth-color',
  };
  // Determine the CSS class based on the props.size value
  const sizeClass = buttonSizes[props.size] || 'sm';
  const colorClass = buttonColors[props.color] || 'Primary-Button';
  return (
    <button
      type={props.type || 'button'}
      className={`button cursor-pointer Button-text text-decoration-none ${
        props.className || ''
      } ${sizeClass} ${colorClass}`}
      onClick={props.click}
      disabled={props.disabled}
      key={getRandomID()}
      // style={{ background: "#753CB7", color: "white" }}
    >
      {props.text}
      {props.children}
    </button>
  );
};
