import React from 'react';
import { API_URI, ASSETS_URI } from '../Constant';
import Axios from './Axios';

/**===================================================== Cache Code ==================================================================== */

// const imageCache = {}; // Store cached image URLs
// const pdfCache = {};   // Store cached PDF data

// // Load cached image URLs and PDF data from local storage
// const storedImageCache = localStorage.getItem('imageCache');
// const storedPdfCache = localStorage.getItem('pdfCache');

// if (storedImageCache) {
//   try {
//     Object.assign(imageCache, JSON.parse(storedImageCache));
//   } catch (error) {
//     console.error('Error parsing cached image data:', error);
//   }
// }

// if (storedPdfCache) {
//   try {
//     Object.assign(pdfCache, JSON.parse(storedPdfCache));
//   } catch (error) {
//     console.error('Error parsing cached PDF data:', error);
//   }
// }

// export const getImageSrc = async (pdfID, pageNo = 1, size = 1) => {
//   if (!pdfID) return false;
//   const cacheKey = `${pdfID}-${pageNo}-${size}`;

//   if (imageCache[cacheKey]) {
//     return imageCache[cacheKey]; // Return cached URL if available
//   }

//   try {
//     const response = await Axios.get(`${API_URI}/clients/view-image.dll?id=${pdfID}&count=${pageNo}&imagesize=${size}`);
//     if (response.status === 202) {
//       const etag = response.headers.etag;
//       const cachedUrl = localStorage.getItem(etag);

//       if (cachedUrl) {
//         imageCache[cachedUrl] = cachedUrl;
//         saveImageCache(); // Save updated imageCache to local storage
//         return cachedUrl; // Return the cached URL if available
//       }

//       const imageUrl = `${ASSETS_URI}/${response.data.data}`;
//       localStorage.setItem(etag, imageUrl); // Cache the ETag with the corresponding URL
//       imageCache[cacheKey] = imageUrl;
//       saveImageCache(); // Save updated imageCache to local storage
//       return imageUrl;
//     } else {
//       console.error('Error fetching image:', response.status, response.statusText);
//       return null;
//     }
//   } catch (error) {
//     console.error('Fetch error:', error);
//     return null;
//   }
// };

// export const getpdfgeneratesrc = async (pdfID, pageNo = 1, size = 1) => {
//   if (!pdfID) return false;
//   const cacheKey = `${pdfID}-${pageNo}-${size}`;
//   // Return cached PDF data if available
//   if (pdfCache[cacheKey]) {
//     return pdfCache[cacheKey];
//   }

//   try {
//     const response = await Axios.get(`${API_URI}/clients/view-image.dll?id=${pdfID}&count=${pageNo}&imagesize=${size}`);

//     if (response?.data?.success) {
//       const etag = response.headers.etag;

//       // Use cached PDF data if ETag matches
//       if (pdfCache[etag]) {
//         return pdfCache[etag];
//       }

//       const pdfData = response.data.data;
//       pdfCache[cacheKey] = pdfData;
//       pdfCache[etag] = pdfData;
//       savePdfCache(); // Save updated pdfCache to local storage
//       return pdfData;
//     } else {
//       console.error('Error fetching PDF:', response?.data?.error || 'Unknown error');
//       return null;
//     }
//   } catch (error) {
//     console.error('Try catch error:', error);
//     return null;
//   }
// };

// export const getS3ImageSrc = (company, pdfid, i, name) => {
//   if (company && name) {
//     const url = `${ASSETS_URI}/${company}/${pdfid}/${i}/${name}`;
//     return url;
//   } else {
//     return '';
//   }
// };

// // Save imageCache to local storage
// const saveImageCache = () => {
//   localStorage.setItem('imageCache', JSON.stringify(imageCache));
// };

// // Save pdfCache to local storage
// const savePdfCache = () => {

//   localStorage.setItem('pdfCache', JSON.stringify(pdfCache));
// };

/**=================================================End Cache Code ==================================================================== */

export const getImageSrc = async (pdfID, pageNo = 1, size = 1) => {
  if (!pdfID) return false;
  try {
    const response = await Axios.get(
      `${API_URI}/clients/view-image.dll?id=${pdfID}&count=${pageNo}&imagesize=${size}`,
    );
    if (response.status === 202) {
      const etag = response.headers.etag;
      const cachedUrl = localStorage.getItem(etag); // Checking if the ETag is already cached
      if (cachedUrl === etag) {
        return cachedUrl;
      }
      const imageUrl = `${ASSETS_URI}/${response.data.data}`;
      localStorage.setItem(etag, imageUrl); // Caching the ETag with the corresponding URL
      return imageUrl;
    } else {
      console.error('Error fetching image:', response.status, response.statusText);
      return null;
    }
  } catch (error) {
    console.error('Fetch error:', error);
    return null;
  }
};

export const getpdfgeneratesrc = async (pdfID, pageNo = 1, size = 1) => {
  if (!pdfID) return false;
  try {
    const result = await Axios.get(
      `${API_URI}/clients/view-image.dll?id=${pdfID}&count=${pageNo}&imagesize=${size}`,
    );
    if (result?.data?.success) {
      return result?.data?.data;
    }
  } catch (error) {
    console.error('Try catch error:', error);
    return null;
  }
};

export const getS3ImageSrc = (company, pdfid, i, name) => {
  if (company && name) {
    var url = `${ASSETS_URI}/${company}/${pdfid}/${i}/${name}`;
    return url;
  } else {
    return '';
  }
};

export function getRandomID(count = 8) {
  var length = count;
  var timestamp = +new Date();

  var _getRandomInt = function (min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  var ts = timestamp.toString();
  var parts = ts.split('').reverse();
  var id = '';

  for (var i = 0; i < length; ++i) {
    var index = _getRandomInt(0, parts.length - 1);
    id += parts[index];
  }

  return id;
}

export const svgIcon = (term) => {
  return (
    <svg
      className="icon"
      width="30"
      height="30"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <use xlinkHref={`#${term}`} />
    </svg>
  );
};

export const getExpireDate = (days = 7) => {
  var date = new Date();
  var expiry = new Date(date.getTime() + days * 24 * 60 * 60 * 1000);
  return expiry;
};

export const truncateString = (string, length) => {
  if (string?.length > length) {
    const elipse = '...';
    return string?.substring(0, length - elipse.length) + elipse;
  } else {
    return string;
  }
};

export const getFormatCurrency = (num = 0) => {
  let currencyCode = localStorage.getItem('currencyCode');
  return new Intl.NumberFormat({
    style: 'currency',
    currency: currencyCode ? currencyCode : 'INR',
  }).format(num);
};
