
import { DropdownProperty, MultiLineProperty, MultipleChoiceProperty, NumberProperty, SingleChoiceProperty, SingleLineProperty } from './Fields/BasicFielsProperties.js';
import { NameTypes, ReCaptchaType, TermsType } from './Fields/FieldTypes.js';
import { AddressProperty, ContactProperty, DateTimeProperty, EmailProperty, NameProperty, ReCaptchaProperty, SliderProperty, TermConditionProperty, UrlProperty } from './Fields/FieldsProperties.js';
import { IconType } from './iconTypes.js';

export const EditorFields = {
  Heading: 'Fields:Type:Heading',
  Name: 'Fields:Type:Name',
  Email: 'Fields:Type:Email',
  Address: 'Fields:Type:Address',
  ContactNumber: 'Fields:Type:ContactNumber',
  DateTime: 'Fields:Type:DateTime',
  Slider: 'Fields:Type:Slider',
  URL: 'Fields:Type:URL',
  TermsConditions: 'Fields:Type:TermsConditions',
  reCAPTCHA: 'Fields:Type:reCAPTCHA'
};


export const BasicFields = {
  SingleLine: "Fields:Basic:SingleLine",
  MultiLine: "Fields:Basic:MultiLine",
  Dropdown: "Fields:Basic:Dropdown",
  SingleChoice: "Fields:Basic:SingleChoice",
  MultipleChoice: "Fields:Basic:MultipleChoice",
  Number: "Fields:Basic:Number",
}


// export type EditorToolItemConfig = {
//   type: EditorFields;
//   title: string;
//   icon: IconType;
//   description?: string;
// };

export const EditorToolConfigMap = {
  [EditorFields.Heading]: {
    title: 'Heading',
    type: EditorFields.Heading,
    icon: IconType.ADD,
  },
  [EditorFields.Name]: {
    title: 'Name',
    type: EditorFields.Name,
    icon: IconType.ADD,
  },
  [EditorFields.Email]: {
    title: 'Email',
    type: EditorFields.Email,
    icon: IconType.ADD,
  },
  [EditorFields.Address]: {
    title: 'Address',
    type: EditorFields.Address,
    icon: IconType.ADD,
  },
  [EditorFields.ContactNumber]: {
    title: 'Contact Number',
    type: EditorFields.ContactNumber,
    icon: IconType.ADD,
  },
  [EditorFields.DateTime]: {
    title: 'Date & Time Picker',
    type: EditorFields.DateTime,
    icon: IconType.ADD,
  },
  [EditorFields.Slider]: {
    title: 'Slider',
    type: EditorFields.Slider,
    icon: IconType.ADD,
  },
  [EditorFields.URL]: {
    title: 'URL Field',
    type: EditorFields.URL,
    icon: IconType.ADD,
  },
  [EditorFields.TermsConditions]: {
    title: 'Terms & Conditions',
    type: EditorFields.TermsConditions,
    icon: IconType.ADD,
  },
  [EditorFields.reCAPTCHA]: {
    title: 'ReCaptcha',
    type: EditorFields.reCAPTCHA,
    icon: IconType.ADD,
  },
  [BasicFields.SingleLine]: {
    title: 'Single Line Text',
    type: BasicFields.SingleLine,
    icon: IconType.ADD,
  },
  [BasicFields.MultiLine]: {
    title: 'Multi Line Text',
    type: BasicFields.MultiLine,
    icon: IconType.ADD,
  },
  [BasicFields.Dropdown]: {
    title: 'Dropdown',
    type: BasicFields.Dropdown,
    icon: IconType.ADD,
  },
  [BasicFields.SingleChoice]: {
    title: 'Single Choice',
    type: BasicFields.SingleChoice,
    icon: IconType.ADD,
  },
  [BasicFields.MultipleChoice]: {
    title: 'Multiple Choice',
    type: BasicFields.MultipleChoice,
    icon: IconType.ADD,
  },
  [BasicFields.Number]: {
    title: 'Number',
    type: BasicFields.Number,
    icon: IconType.ADD,
  },
};

export const FieldsProperties = {

  [EditorFields.Heading]: {
    property: [
      { label: "Heading Text", type: "text", name: "heading", default: "Form", required: true },
      { label: "Sub-Heading text", type: "text", name: "subheading", default: "Form", required: false }
    ],
    values: {
      heading: "Form",
      subheading: "",
      type: EditorFields.Heading,
      validation: {
        heading: "heading",
        subheading: "subheading",
      }
    }
  },
  [EditorFields.Name]: {
    property: [
      ...NameProperty[NameTypes.Segregated].property,
    ],
    values: {
      label: "",
      type: EditorFields.Name,
      validation: { ...NameProperty[NameTypes.Segregated].validation }
    }
  },
  [EditorFields.Email]: {
    property: EmailProperty.property,
    values: {
      label: "",
      type: EditorFields.Email,
      validation: EmailProperty.validation
    },
  },
  [EditorFields.Address]: {
    property: AddressProperty.property,
    values: {
      label: "",
      type: EditorFields.Address,
      validation: AddressProperty.validation
    },
  },
  [EditorFields.ContactNumber]: {
    property: ContactProperty.property,
    values: {
      label: "",
      type: EditorFields.ContactNumber,
      validation: ContactProperty.validation
    },
  },
  [EditorFields.DateTime]: {
    property: DateTimeProperty.property,
    values: {
      label: "",
      type: EditorFields.DateTime,
      validation: DateTimeProperty.validation
    },
  },
  [EditorFields.Slider]: {
    property: SliderProperty.property,
    values: {
      label: "",
      type: EditorFields.Slider,
      validation: SliderProperty.validation
    },
  },
  [EditorFields.URL]: {
    property: UrlProperty.property,
    values: {
      label: "",
      type: EditorFields.URL,
      validation: UrlProperty.validation
    },
  },
  [EditorFields.TermsConditions]: {
    property: TermConditionProperty[TermsType['With Terms & Conditions text field']].property,
    values: {
      label: "",
      type: EditorFields.TermsConditions,
      validation: TermConditionProperty[TermsType['With Terms & Conditions text field']].validation
    },
  },
  [BasicFields.SingleLine]: {
    property: SingleLineProperty.property,
    values: {
      label: "",
      type: BasicFields.SingleLine,
      validation: SingleLineProperty.validation
    },
  },
  [BasicFields.MultiLine]: {
    property: MultiLineProperty.property,
    values: {
      label: "",
      type: BasicFields.MultiLine,
      validation: MultiLineProperty.validation
    },
  },
  [BasicFields.Dropdown]: {
    property: DropdownProperty.property,
    values: {
      label: "",
      type: BasicFields.Dropdown,
      validation: DropdownProperty.validation
    },
  },
  [BasicFields.SingleChoice]: {
    property: SingleChoiceProperty.property,
    values: {
      label: "",
      type: BasicFields.SingleChoice,
      validation: SingleChoiceProperty.validation
    },
  },
  [BasicFields.MultipleChoice]: {
    property: MultipleChoiceProperty.property,
    values: {
      label: "",
      type: BasicFields.MultipleChoice,
      validation: MultipleChoiceProperty.validation
    },
  },
  [BasicFields.Number]: {
    property: NumberProperty.property,
    values: {
      label: "",
      type: BasicFields.Number,
      validation: NumberProperty.validation
    },
  },
  [EditorFields.reCAPTCHA]: {
    property: ReCaptchaProperty[ReCaptchaType['Google Captcha']].property,
    values: {
      label: "",
      type: EditorFields.reCAPTCHA,
      validation: ReCaptchaProperty[ReCaptchaType['Google Captcha']].validation
    },
  },
}

export const EditorFieldsTypes = {
  [EditorFields.Name]: NameProperty,
  [EditorFields.TermsConditions]: TermConditionProperty,
  [EditorFields.reCAPTCHA]:ReCaptchaProperty
}




