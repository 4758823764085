import {
  GET_PDF_LIST,
  GET_PDF_LIST_SUCCESS,
  GET_PDF_LIST_ERROR,
  UPDATE_PDF,
  UPDATE_PDF_SUCCESS,
  UPDATE_PDF_ERROR,
  DELETE_PDF,
  DELETE_PDF_SUCCESS,
  DELETE_PDF_ERROR,
  PDF_STATUS_UPDATE,
  PDF_LISTSTATUS_UPDATE,
} from '../actions/types';

const initialState = {
  loading: true,
  pdfList: [],
  error: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PDF_LIST:
      return {
        ...state,
        loading: true,
      };

    case GET_PDF_LIST_SUCCESS:
      const activeItems = action.payload.filter(item => item.pdfstatus === true);
      const otherItems = action.payload.filter(item => item.pdfstatus !== true);
      const sortedPayload = [...activeItems, ...otherItems];
      return {
        ...state,
        pdfList: sortedPayload,
        loading: false,
        error: false,
      };

    case GET_PDF_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
      };

    case UPDATE_PDF:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_PDF_SUCCESS:
      return {
        ...state,
        pdfList: state.pdfList.map((pdf) => {
          if (pdf.pdfID === action.payload.pdfID) {
            return {
              ...pdf,
              ...action.payload,
            };
          }
          return pdf;
        }),
        loading: false,
        error: false,
      };
    case UPDATE_PDF_ERROR:
      return {
        ...state,
        error: true,
        errorMessage: action.payload,
        loading: false,
      };
    case DELETE_PDF:
      return {
        ...state,
        loading: true,
      };

    case DELETE_PDF_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        pdfList: state.pdfList.filter((pdf) => pdf.pdfID !== action.payload),
      };

    case DELETE_PDF_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: 'Could not delete PDF',
      };

    case PDF_STATUS_UPDATE:
      return {
        ...state,
        pdfList: state.pdfList.map((pdf) => {
          if (pdf.pdfID === action.payload.pdfID) {
            return {
              ...pdf,
              pdfstatus: action.payload.pdfstatus,
            };
          }
          return pdf;
        }),
      };
    case PDF_LISTSTATUS_UPDATE:
      return {
        ...state,
        pdfList: state.pdfList.map((pdf) => {
          if (pdf.pdfID === action.payload.pdfID) {
            return {
              ...pdf,
              pdflistStatus: action.payload.pdflistStatus,
            };
          }
          return pdf;
        }),
      };
    default:
      return state;
  }
};
