import axios from 'axios';
import { getRefreshToken, getToken, removeToken, saveRefreshToken, saveToken } from './Auth';
import store from '../store';
import { API_URI } from 'Constant';
const Axios = axios.create();

Axios.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    } else {
      return config;
    }
  },
  (Err) => {
    return Promise.reject(Err);
  },
);

/** ==========================Intercepter for Etag Caching=====================================  */

// let etagCache = {}; // Store ETag values for each URL

// Axios.interceptors.request.use(
//   (config) => {
//      
//     const token = getToken();
//     if (token) {
//       config.headers.Authorization = `Bearer ${token}`;
//     }
//     // Include ETag header from cache if available
//     if (etagCache[config.url]) {
//       config.headers['If-None-Match'] = etagCache[config.url];
//     }

//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

// Axios.interceptors.response.use(
//   (response) => {
//     // Check if the response contains an ETag header
//     const eTag = response.headers['etag'];
//     if (eTag) {
//       // Store ETag value in cache
//       etagCache[response.config.url] = eTag;
//     }

//     return response;
//   },
//   (error) => {
//     if (error.response) {
//       // Handle 304 Not Modified response
//       if (error.response.status === 304) {
//         // Use cached data since the resource hasn't changed
//         const cachedResponse = error.response.config.cachedResponse;
//         return Promise.resolve(cachedResponse);
//       }

//       // Handle other error responses
//       // ...

//       return Promise.reject(error);
//     } else {
//       return Promise.reject(error);
//     }
//   }
// );

/** ========================================END================================================ */

Axios.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    if (err.response && err.response.status === 401) {
      // const refresh = await Axios.post(`${API_URI}/api/auth/refreshtokenapp`, {
      //   refreshToken: getRefreshToken()
      // })
      // if (refresh.status === 200) {
      //   saveToken(refresh.data.accessToken, refresh.data.expiryDuration);
      // } else {
        removeToken();
        store.dispatch({ type: 'USER_LOGOUT' });
        window.location.href = window.location.origin + '/login';
      // }

    }
    return Promise.reject(err);
  },
);

export const handelError = (Err) => {
  let errMsg = 'Something Went Wrong';
  if (Err && Err.message) errMsg = Err.message;
  return {
    error: true,
    message: errMsg,
  };
};

export const header = {
  headers: {
    'content-type': `application/json`,
  },
};

export default Axios;
